import { Card, TextContainer, Heading } from "@shopify/polaris";
import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { GETTING_STARTED_ENTERPRISE_ADMIN_STEPS } from "../../../constants/dashboard";
import { updateCurrentActiveStep } from "../../../actions/dashboard";
import { useHistory } from "react-router-dom";
import { setCustomizeButtonHit } from "../../../api/dashboard";
import AppButton from "../../../components/common/AppButton";
import { getFirstIncompleteStep } from "../../../helpers/common";
import {
  timeLineDotColor,
  BorderLinearProgress,
} from "../../../helpers/timeline";

function GettingStartedEnterpriseAdmin({ primaryColor }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentActiveStep = useSelector(
    (state) => state?.dashboard?.currentActiveStep
  );
  const stepsCompleted = useSelector(
    (state) => state?.dashboard?.gettingStartedEnterpriseAdminStepsDone
  );

  let steps = Object.values(GETTING_STARTED_ENTERPRISE_ADMIN_STEPS);
  steps = steps.sort((a, b) => a.rankOrder - b.rankOrder);

  const firstIncompleteStep = getFirstIncompleteStep(stepsCompleted, steps);

  const [expandedStep, setExpandedStep] = useState(
    currentActiveStep || firstIncompleteStep
  );

  const onButtonClick = (key) => {
    const clickedStep = GETTING_STARTED_ENTERPRISE_ADMIN_STEPS[key];

    if (clickedStep.mappingKey === "customizeButton") {
      setCustomizeButtonHit({ customizeButton: true })
        .then(() => {})
        .catch((err) => console.log(err));
    }

    if (clickedStep?.link) {
      return window.open(clickedStep?.link, "_blank");
    }

    dispatch(updateCurrentActiveStep(key));
    history.push(clickedStep?.redirectRoute);
  };

  return (
    <Card>
      <Card.Header title="Getting Started"></Card.Header>
      <Card.Section>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextContainer>
            {stepsCompleted.length} of {steps.length} tasks complete
          </TextContainer>

          <div style={{ flex: "1 1", marginLeft: "1rem" }}>
            <BorderLinearProgress
              variant="determinate"
              value={(stepsCompleted.length / steps.length) * 100}
              primaryColor={primaryColor}
            />
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        <Timeline align="left">
          {steps.map(({ key, title, content, buttonText }, i) => (
            <TimelineItem key={key}>
              <TimelineOppositeContent
                style={{
                  maxWidth: "1px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              />
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    bgcolor: timeLineDotColor(
                      stepsCompleted,
                      primaryColor,
                      key
                    ),
                  }}
                  variant={stepsCompleted.includes(key) ? "filled" : "outlined"}
                />
                {i !== steps.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <div style={{ padding: "0px 10px 20px 10px" }}>
                  {expandedStep !== key && (
                    <span role="button" onClick={() => setExpandedStep(key)}>
                      {title}
                    </span>
                  )}
                  {expandedStep === key && (
                    <>
                      <Heading>{title}</Heading>
                      <Typography className="my-3">{content}</Typography>
                      {buttonText && (
                        <AppButton
                          primary
                          size="slim"
                          onClick={() => onButtonClick(key)}
                        >
                          {buttonText}
                        </AppButton>
                      )}
                    </>
                  )}
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Card.Section>
    </Card>
  );
}

export default GettingStartedEnterpriseAdmin;
