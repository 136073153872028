import { LOCALSTORAGE_KEYS } from "../constants/users";
import { getToken } from "../helpers/localStorageCache";

export const currentDomain = (function () {
  return (
    getToken(LOCALSTORAGE_KEYS.mockUser)?.currentDomain ||
    window.location.hostname
  );
})();

export const isHoaxMerchDomain = (function () {
  // return window.location.hostname === "app.hoaxmerch.com";
  return currentDomain === "app.themerchyard.com";
})();

export const isStagingDomain = (function () {
  return currentDomain === "riverr-enterprise-user.web.app";
})();

export const isLocalhost = (function () {
  return currentDomain === "localhost";
})();

export const isDevDomain = (function () {
  return isLocalhost || isStagingDomain;
})();

export const isLarevivalDomain = (function () {
  // return currentDomain === "larevival.myriverr.com";
  return currentDomain === "app.larevivalapparel.com";
})();

export const isGirlTribeCoDomain = (function () {
  return currentDomain === "app.girltribeco.com";
})();

export const isPrintPoetsDomain = (function () {
  return currentDomain === "app.printpoets.com";
})();

export const isRakilineDomain = (function () {
  return currentDomain === "app.rakiline.com";
})();

export const isTeeprintcentreDomain = (function () {
  return currentDomain === "app.teeprintcentre.com.au";
})();

export const isRakilineEuDomain = (function () {
  return currentDomain === "app.rakiline.eu";
})();

export const isPodhutDomain = (function () {
  return currentDomain === "app.podhut.com";
})();

export const isRiverrDomain = (function () {
  return currentDomain === "app.riverr.app";
})();

export const isBespokeThreadsDomain = (function () {
  return currentDomain === "app.bespokethreads.com";
})();

export const isTeePrintCenterDomain = (function () {
  return currentDomain === "app.teeprintcentre.com.au";
})();

export const isMerchfillerDomain = (function () {
  return currentDomain === "app.merchfiller.com";
})();

export const isPrintifyDomain = (function () {
  return (
    currentDomain === "printify.myriverr.com" ||
    currentDomain === "locationstest.myriverr.com"
    // currentDomain === "bigfrog.myriverr.com"
  );
})();

export const isPrintOnDemandDomain = (function () {
  return currentDomain === "app.printondemand.com.ph";
})();

export const isMyShirtPodDomain = (function () {
  return currentDomain === "app.myshirtpod.com";
})();

export const isJmDtgPrintingDomain = (function () {
  return currentDomain === "app.jmdtgprinting.net";
})();

export const isDtgShirtsDomain = (function () {
  return currentDomain === "app.dtgtshirts.co.uk";
})();

export const isDropmerchDomain = (function () {
  return currentDomain === "app.dropmerch.co.uk";
})();

export const isBackStoryPrintsDomain = (function () {
  return currentDomain === "app.backstoryprints.com";
})();

export const isLuxtownDomain = (function () {
  return currentDomain === "app.luxtown.com";
})();

export const isAimlessGoodsDomian = (function () {
  return currentDomain === "app.aimlessgoods.com";
})();

export const isJumbodtgDomian = (function () {
  return currentDomain === "app.jumbodtg.com";
})();

export const isTheSublimationStationDomian = (function () {
  return currentDomain === "app.thesublimationstation.com";
})();

export const isPromiseApparelDomian = (function () {
  return currentDomain === "app.promiseapparel.com";
})();

export const isDallasPrintHouseDomian = (function () {
  return currentDomain === "app.dallasprinthouse.com";
})();

export const isThinkProDomian = (function () {
  return currentDomain === "app.think.pro";
})();

export const isPrintDropDomian = (function () {
  return currentDomain === "app.printdrop.cl";
})();

export const domainName = (function () {
  let DN = currentDomain.split(".");
  DN = DN.length > 2 ? DN[1] : DN[0];
  return DN;
})();

export const isRiverrEnterprise = (enterpriseId) => {
  return enterpriseId === "cfbZh6XFBG3usCcUwpRE";
};

const BETA_DOMAINS = [
  "localhost",
  "riverr-enterprise-user.web.app",
  "app.bespokethreads.com",
  "app.themerchyard.com",
  "app.rakiline.com",
];

export const isBetaDomain = (function () {
  return BETA_DOMAINS.includes(currentDomain);
})();

const HOME_ICON_LINK = {
  "app.podhut.com": "//podhut.com",
  default: `//${window.location.host}`,
};

export const homeIconLink = (function () {
  return HOME_ICON_LINK[currentDomain] || HOME_ICON_LINK["default"];
})();

export const currencySymbol = (function () {
  let symbol = "$";

  if (isTeePrintCenterDomain) {
    symbol = "AUD";
  }

  return symbol;
})();
